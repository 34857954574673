body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #333;
  overflow-x: hidden;
}

.v-header {
  height: 80vh;
  display: flex;
  align-items: center;
  color: #fff;
}

.container {
  max-width: 960px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.fullscreen-video-wrap {
  position: absolute;
  top: -10;
  left: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.fullscreen-video-wrap video {
  min-height: 100%;
  min-width: 100%;
}

.header-overlay {
  height: 80vh;
  position: absolute;
  top: 1;
  left: 0;
  width: 100vw;
  z-index: 1;
  background: #284150;
  opacity: 0.75;
}

.header-content {
  z-index: 2;
}

.header-content h1 {
  font-size: 50px;
  margin-bottom: 0;
}

.header-content p {
  font-size: 1.5rem;
  display: block;
  padding-bottom: 2rem;
}

.btn {
  background: #279fea;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  text-decoration: none;
}

.section {
  padding: 20px 0;
}

.section-b {
  background: #333;
  color: #fff;
}

@media (max-width: 960px) {
  .container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
