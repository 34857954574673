.contact {


    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .contactBanner {
        width: 75%;
        background-color: #279FEA;

        h2 {
            font-size: 36px;
            padding-left: 10%;
            color: white;
        }
    }

    .contactTop {
        width: 50%;

        p {
            color: #07406B;
            font-size: 28px;

        }

    }

    .contactBottom {

        display: flex;
        flex-direction: row;
        align-items: center;
        width: 70%;
        padding-bottom: 5%;
            
        .contactInfo {
            width: 40%;
            padding-left: 10%;

            h2 {
                font-size: 48px;
                display: flex;
                justify-content: center;
                padding-right: 25%;
                color: #07406B;
            }
            p {
                // display: flex;
                // justify-content: center;
                color: #07406B;
                font-size: 24px;
            }
        }

        .map {
            width: 60%;

            img {
                width: 100%;
            }
        }
    }

    @media screen and (max-width:1200px) {
        .contactBanner {
            width: 100%;
        }
        .contactTop {
            width: 90%;
            
            p {
                font-size: 26px;
            }
        }
        .contactBottom {

            width: 90%;
           display: flex;
           flex-direction: column-reverse;
           
           .map {
               width: 100%;
           }

           .contactInfo {
               width: 100%;
               p {
                   font-size: 26px;
               }
           }
        }
    }
}