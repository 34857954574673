.NavbarItems {
    background: linear-gradient(90deg, rgb(23, 112, 171) 0%, rgba(7,64,107) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Black Ops One',sans-serif;
}

.navbar-logo {
    // width: 10%;
    width: 15%;
    img {
        margin:5px 0 0 15px;
        height: 70%;
        width: 70%;
        font-family: 'black-ops-one',sans-serif;
    }
}

//     cursor: pointer;
//     line-height: 24px;
   
//     padding-top: 10px;
//     margin-left: 5px;
//     // background-color: #99B9DB ;
//     // padding: 2px;
//     a:link {
//         text-decoration: none;
//     }
//     a :visited {
//         text-decoration: none; color: #1770ab;
//     }

//     P {
//         display: flex;
//         justify-content: center;
//         color: white;
//         margin: 0;
//     }

//     .p1 {
//         font-size: 42px;
//     }
//     // img {
//     //     float: left;
//     //     height: 80px;
//     //     width: 80px;
//     // }
// }
//     .p2 {
//         font-size: 18px;
//     }

.navItem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: 26px;
        
        h3 {
            
            
        }
        
       
        img {
            width: 40px;
        }

        a { color: white;}
        a:visited { text-decoration: none; color: white; }
        a:link { text-decoration:none;}
        a:hover { color:#279FEA; }
    }


.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: end;
    margin-right: 2rem;
}

// .nav-links {
//     color: white;
//     text-decoration: none;
//     padding: 0.5rem 1rem;
// }

// .nav-links:hover {
//     background-color: #6d76f7;
//     border-radius: 4px;
//     transition: all 0.2s ease-out;
// }

// Hamburger Menu
.fa-bars {
    color: #fff;
}

// .nav-links-mobile {
//     display: none;
// }

// Hamburger
.menu-icon {
    display: none;
}

@media screen and (max-width: 1500px) {
    .navbar-logo {
        width: 20%;
    }

    .navItem {
        font-size: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .navbar-logo {
        width: 20%;
    }
    
    .navItem {
        font-size: 20px;
    }
}

@media screen and (max-width: 960px) {
    .navbar-logo {
        width: 25%;
        img {
            width: 80%;
        }
    }

    .NavbarItems {
        position: relative;
  
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        height: 700px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

    }

    .nav-menu.active {
        background: #1770ab;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 3;

        
    }

    .navItem {
        display: flex;
        justify-content: center;
        flex-direction: row;
        font-size: 28px;
        
        a:visited { text-decoration: none; color: white; }
        a:hover { color:#279FEA; }
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #7577fa;
        border-radius: 0;
    }

    .navbar-logo {
        padding-right: 70%;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #6568F4;
        transition: 250ms;
    }

    // Button {
    //     display: none;
    // }

    @media screen and (max-width: 500px)
    {
        .navbar-logo {
           
            .p1 {
                font-size: 36px;
            }

            .p2 {
                font-size: 14px;
            }
        }
        
    }
}

@media screen and (max-width:600px) {
    .navbar-logo {
        width: 30%;
        img {
            width: 100%;
        }
    }
}

