.solutions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  .solutionsBanner {
    width: 75%;
    background-color: #279fea;

    h2 {
      font-size: 36px;
      padding-left: 10%;
      color: white;
    }
  }

  .solutionsHero {
    width: 55%;
    padding-bottom: 60px;

    img {
      width: 100%;
    }
  }

  .solutionsMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    flex-direction: column;
    width: 75%;
    padding-bottom: 5%;

    .topMenu {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;

      .milling {
        width: 40%;
      }

      .turning {
        width: 40%;
      }

      img {
        width: 90%;
        // height: 250px;
        padding-top: 5%;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
    .middleMenu {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .laser {
        width: 40%;
        height: 90%;
      }

      .wire {
        width: 40%;
        height: 90%;
      }

      img {
        width: 90%;
        height: 100%;
        padding-top: 5%;
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    .bottomMenu {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .stamping {
        width: 40%;
      }

      .hiddenBox {
        width: 40%;
        visibility: hidden;
      }

      img {
        width: 90%;
        // height: 250px;
        padding-top: 5%;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .solutionsMenu {
      .topMenu {
        .milling {
          width: 50%;
        }

        .turning {
          width: 50%;
        }
      }

      .middleMenu {
        .laser {
          width: 50%;
        }

        .wire {
          width: 50%;
        }
      }

      .bottomMenu {
        .stamping {
          width: 50%;
        }

        .hiddenBox {
          width: 50%;
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .solutionsBanner {
      width: 100%;
      h2 {
        font-size: 28px;
      }
    }

    .solutionsHero {
      width: 100%;
    }
    .solutionsMenu {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .topMenu {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .milling {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80%;
        }

        .turning {
          width: 80%;
        }
      }

      .middleMenu {
        flex-direction: column;
        width: 100%;

        .laser {
          width: 80%;
        }

        .wire {
          width: 80%;
        }
      }

      img {
        width: 90%;
      }

      .bottomMenu {
        flex-direction: column;
        width: 100%;

        .stamping {
          width: 80%;
        }

        .hiddenBox {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .solutionsMenu {
      width: 90%;
      //     .topMenu {
      //         .milling {
      //             img {
      //                 width: 100%;
      //             }
      //         }

      //         .turning {
      //             img {
      //                 width: 100%;
      //             }
      //         }

      //     }

      //         .bottomMenu {

      //             .laser {
      //                 img {
      //                     width:100%;
      //                 }
      //             }

      //             .wire {
      //                 img {
      //                     width: 100%;
      //                 }
      //             }

      //     }
      // }
    }
  }
}
