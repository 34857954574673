.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    // .fullscreen-video-wrap {
       
    // }

    Button {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
        font-weight: bold;
        background: #279FEA;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;
    }

    button:hover, Button:focus, Button:active{
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .hero   {
        width: 75%;
        padding-bottom: 60px;
        
        img {
            width: 100%;
        }
    }

    .homeDivider {
        background-color: #279FEA;
        display: flex;
        justify-content: center;
        width: 85%;
        padding-left: 0;
        margin-top: 60px;
        h2 {
            font-size: 52px;
            color: white;
            margin: 0;
        }
    }

    .features {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 5%;
    }

    .lightsOut {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        padding-top: 80px;
        

        .lightsImage {
            width: 55%;
            img {
    
                width: 100%;
            }
        }

        .lightsText {
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 5%;

            

            h3 {
                display: flex;
                justify-content: center;
                font-size: 36px;
                color: #07406B;
                margin-top: 0;
                margin-bottom: 5px;
            }
            
            p { display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 20px;
                color: #07406B;
            }


            span {
                display: flex;
                color: white;
                font-size: 16px;
            }

        }
        
    } 
    .kanban {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        padding-top: 80px;
        
        
        .kanbanImage {
            width: 50%;

            img {
                width: 100%;
            }
        }

        .kanbanText{
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: 5%;
            
            
            h3 {
                margin-bottom: 5px;
                display: flex;
                justify-content: center;
                font-size: 36px;
                color: #07406B;
                margin-top: 0;
            }
    
            p {
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 20px;
                color: #07406B;
            }
           
            span {
                display: flex;
                color: white;
                font-size: 16px;
            }
        }
    }

    .qualityAssurance {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        padding-top: 80px;
        
        .qualityImage {
            width: 50%;
            img {
                width: 100%;
            }
        }

        .qualityText{
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 5%;
            h3 {
                margin-bottom: 5px;
                display: flex;
                justify-content: center;
                font-size: 36px;
                color: #07406B;
                margin-top: 0;
            }
    
            p {
                margin-top: 10px;
                margin-bottom: 10px;
                font-size: 20px;
                color: #07406B;
            }

            span {
                display: flex;
                color: white;
                font-size: 16px;
            }
        }
    
    }
    
    

    @media screen and (max-width: 1660px) {
        .lightsOut { 
            .lightsImage {
                width: 50%;
            }

           .lightsText{
               width: 50%;
                h3 {
                    font-size: 28px;  
                }
                p {
                    font-size: 18px;
                }
            }
        }
        .kanban { 
            .kanbanImage {
                width: 50%;
            }

            .kanbanText{
                width: 50%;
                 h3 {
                     font-size: 28px;  
                 }
                 p {
                     font-size: 18px;
                 }
             }
         }
         .qualityAssurance { 

            .qualityText{
                 h3 {
                     font-size: 28px;  
                 }
                 p {
                     font-size: 18px;
                 }
             }
         }
       .homeDivider {
           h2 {
               font-size: 48px;
           }
       }
    }

    @media screen and (max-width: 1250px) {
        .homeDivider {
            h2 {
                font-size: 32px;
            }
        }
        .lightsOut { 
    
           .lightsText{
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 16px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;

                }
                span {
                    font-size: 14px;
                    text-align: center;
                    
                }
            }
        }
        .kanban { 
    
            .kanbanText{
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 16px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;
                }
                span {
                    font-size: 14px;
                }
             }
         }
         .qualityAssurance { 
    
            .qualityText{
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 16px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;
                }
                span {
                    font-size: 14px;
                }
             }
         }
       
       }
    
       @media screen and (max-width: 1000px) {
        .hero {
            width: 100%;
        }

        .homeDivider {
            box-sizing: border-box;
            width: 100%;
            h2 {
                font-size: 32px;
            }
        }.features {
            width: 100%;
        .lightsOut { 
            width: 100%;
            display: flex;
            flex-direction: column;

            .lightsImage {
                display: flex;
                justify-content: center;
                width: 80%;
                img {
                    width: 100%;
                }
            }
            
    
           .lightsText{
               width: 80%;
               padding-left: 0;
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 18px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;
                }
                // span {
                //     font-size: 14px;
                // }
            }
        }
        .kanban {
            width: 100%; 
            display: flex;
            flex-direction: column-reverse;

            .kanbanImage {
                display: flex;
                justify-content: center;
                width: 100%;
                img {
                    width: 80%;
                }
            }
            
            .kanbanText{
                width: 80%;
                padding-right: 0;
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 18px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;
                }
                span {
                    font-size: 14px;
                }
             }
         }
         .qualityAssurance { 
            display: flex;
            flex-direction: column;
            width: 100%;

            .qualityImage {
                display: flex;
                justify-content: center;
                width: 100%;
                img {
                    width: 80%;
                }
            }
            .qualityText{
                width: 80%;
                padding-left: 0;
                h3 {
                    font-size: 24px;  
                }
                p {
                    font-size: 18px;
                }
                button {
                    height: 50px;
                    width: 120px;
                    padding: 0 0 0 13px;
                    
                }
                span {
                    font-size: 14px;
                
                }
                
             }
         }
        }
       }
    
       @media screen and (max-width: 700px) {
        .fullscreen-video-wrap {
            video {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }   
        
        
        .homeDivider {
               padding-left: 10%;
           }

           .lightsImage {
            display: flex;
            align-items: flex-start;
       }
    
           .kanbanImage {
                display: flex;
                align-items: flex-start;
           }
    
           .qualityImage {
            display: flex;
            align-items: flex-start;
       }
        
    }
       }

    





