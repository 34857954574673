@import 'home';
@import 'header';
@import 'about';
@import 'solutions';
@import 'quality';
@import 'contact';
@import 'milling';
@import 'turning';
@import 'laser';
@import 'test2';
@import 'wire';
@import 'stamping';


#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

html {
    background-color: #07406B;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #07406B;
    margin: 0;
    
}

main {
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #07406B;

    h4 {
      color: white;  
  }

 
}


  
  
  
  
  
  