.header {

header {
    width: 100%;
    color: #07406B;
  }
  
  nav {
    background-color: #07406B;
  }
  
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin: 0;

    .hamburger {
      display: none !important;
    }
  }
  
  li {
    list-style: none;
    display: flex;
  }
  
  .navItem {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    
    h3 {
        padding-left: 5px;
        color: white;
        font-size: 1.5em;
    }
  
    img {
        height: 40px;
    }
  }

  .contact {
    height: 80px;
    width: 150px;
    background-color: #279FEA;
    margin-right: 10px;
    border-radius: 5%;
    align-items: center;
    justify-content: center;

    .contactDiv {
      display: flex;
      align-items: center;
      
    }

    h4 {
        color: white;
        font-size: 1.3em;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
    }

    a:visited { text-decoration: none; color:#279FEA; }
    a:hover { color:#07406B; }

    img {
        height: 40px;
    }
    span {
      padding-left: 5px;
    }
  }
      
  p {
    padding-left: 10px;
    margin: 0;
  }

  link {
    text-decoration: none;
    list-style: none;
  }

  a:visited { text-decoration: none; color:#07406B; }
  a:hover { color:#279FEA; }
  
 

  button {
    text-decoration: none;
  }

  @media screen and (max-width: 900px) {
    .navItem {
      display: none;
    }
    .hamburger {
      display:block;
    }
  } 
}
  