.turning {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .turningHero {
        display: flex;
        justify-content: center;
        width: 80%;
    }

    .turningEquipment {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        .turningingIntro {
            width: 75%;
            margin-right: 0;
            
            h2 {
                font-size: 48px;
                display: flex;
                justify-content: center;
                color: #07406B;
            }

            p { 
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5%;
                color:  #07406B;
                font-size: 27px;
            }

        }

        .turningBody {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
            p {
                color:  #07406B;
                font-size: 24px;
            }

            span {
                    color:  #07406B;
                    font-size: 27px;
                    font-weight: bolder;
            }

            
        
            .turningLeftText {
                width: 30%;
                padding-right: 5%;
                
            }

            .turningMiddleText {
                padding-left: 5%;
                padding-right: 5%;
                width: 30%;
                border-left: solid #279FEA;
                border-right: solid #279FEA;
            }

            .turningRightText {
                padding-left: 5%;
                width: 30%;
            }
        }
    }


    @media screen and (max-width:1200px)  {    
    .turningHero {
        width: 100%;

        img {
            width: 100%;
        }
    }
    
    .turningEquipment{
        width: 100%;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        .turningBody {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .turningLeftText {
                width: 80%;
                padding: 0;
                border: none;
            }

            .turningMiddleText {
                width: 80%;
                border: none;
            }

            .turningRightText {
            width: 80%;
            padding: 0;
            }
        
    }
    
    }

    }
}

