.milling {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    

    .millingHero {
        display: flex;
        justify-content: center;
        width: 80%;
    }

    .millingEquipment {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .millingIntro {
            width: 75%;
            margin-right: 0;
            
            h2 {
                font-size: 48px;
                display: flex;
                justify-content: center;
                color: #07406B;
            }

            p { 
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 5%;
                color:  #07406B;
                font-size: 27px;
            }

        }

        .millingBody {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            span {
                    color:  #07406B;
                    font-size: 27px;
                    font-weight: bolder;
            }

            p {
                color:  #07406B;
                font-size: 24px;
            }
        
            .leftText {
                width: 30%;
                padding-right: 5%;
                border-right: solid  #279FEA;
            }

            .middleText {
                width: 30%;
                padding-left: 5%;
                padding-right: 5%;
                border-right: solid  #279FEA;
            }

            .rightText {
                padding-left: 5%;
                width: 30%;
            }
        }

    
    
    }

    @media screen and (max-width:1100px){
        .millingHero {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    @media screen and (max-width:900px)  {    
        .millingEquipment{

            .millingIntro {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

            }

            .millingBody {
                display: flex;
                   flex-direction: column;
            
                .leftText {
                    width: 100%;
                    padding-right: 0;
                    border-right: none;
                }
                
                .middleText {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                    border: none;
                }
                
                .rightText {
                    width: 100%;
                }
            }
        }
    }

}