.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    
    .aboutBanner {
        width: 75%;
        background-color: #279FEA;

        h2{
            
            font-size: 36px;
            padding-left: 10%;
            color: white;
        } 
    }

    .dnbTop {
        width: 70%;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-top: 40px;

        .dnbImage {
            width: 55%;

            img {
                width: 90%;
            }
        }

        .dnbText {
            width: 40%;

            p {
                font-weight: 600;
                color: #07406B;
                font-size: 22px;
            }
        }
    }
    .dnbBottom {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5%;

        .dnbSummary {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            p {
                font-weight: 600;
                width: 100%;
                color: #07406B;
                font-size: 22px;
            }
        }
        .contactButton {
            height: 80px;
            width: 150px;
            background-color: #279FEA;
            margin-right: 10px;
            border-radius: 5%;
            align-items: center;
            justify-content: center;

            .contactDiv {
                display: flex;
                flex-direction: row;
                align-items: center;
                
              }
        
            h4 {
                color: white;
                font-size: 1.3em;
                margin: 0;
                padding: 0;
                align-items: center;
                justify-content: center;
            }
        
            a:visited { text-decoration: none; color:#279FEA; }
            a:hover { color:#07406B; }
        
            img {
                height: 40px;
            }

            span {
                padding-left: 5px;
            }
        }
    }

    @media screen and (max-width: 1500px) {
        .dnbTop {

            .dnbText {
            p {
                font-size: 22px;
                }
            }
        }

        .dnbBottom {

            .dnbSummary {
                p {
                    font-size: 22px;
                }
                
            }
        }
    } 

    @media screen and (max-width: 1200px) {
        .dnbTop {

            .dnbText {
            p {
                font-size: 18px;
                }
            }
        }

        .dnbBottom {

            .dnbSummary {
                p {
                    font-size: 18px;
                }
                
            }
        }
    } 

    @media screen and (max-width: 900px) {
        .aboutBanner {
            width: 100%;
        }
        .dnbTop {
            flex-direction: column;

            .dnbImage {
                display: flex;
                justify-content: center;
                width: 100%;
            }

            .dnbText {
                width: 100%;
            p {
                font-size: 18px;
                }
            }
        }

        .dnbBottom {

            .dnbSummary {
                p {
                    font-size: 18px;
                }
                
            }
        }
    } 
    
}