.quality {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  .qualityHero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;

    img {
      width: 100%;
      height: 80%;
    }
  }

  .iso {
    padding-top: 40px;
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .isoImage {
      display: flex;
      align-items: center;
      width: 40%;

      img {
        width: 90%;
      }
    }

    .isoText {
      width: 40%;
      h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #07406b;
        font-size: 32px;
      }

      p {
        color: #07406b;
        font-size: 24px;
      }
    }
  }

  .precisionBanner {
    width: 80%;
    background-color: #279fea;
    display: flex;

    h2 {
      font-size: 36px;
      color: white;
      padding-left: 15%;
    }
  }

  .instruments {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5%;

    .keyence {
      padding-top: 5%;
      padding-bottom: 5%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .keyenceText {
        width: 40%;

        h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #07406b;
          font-size: 28px;
        }

        p {
          color: #07406b;
          font-size: 20px;
        }
      }

      .keyenceImage {
        width: 40%;

        img {
          width: 100%;
          padding-left: 5%;
        }
      }
    }
    .cmm {
      padding-bottom: 5%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .cmmText {
        width: 40%;

        h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #07406b;
          font-size: 28px;
        }

        p {
          color: #07406b;
          font-size: 20px;
        }
      }

      .cmmImage {
        width: 40%;

        img {
          width: 100%;
          padding-left: 5%;
        }
      }
    }

    .measureLink {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding-bottom: 5%;

      .measureLinkText {
        width: 40%;

        h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #07406b;
          font-size: 36px;
        }

        p {
          color: #07406b;
          font-size: 20px;
        }
      }

      .measureLinkImage {
        width: 40%;

        img {
          width: 100%;
          padding-left: 5%;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .qualityHero {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .iso {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      .isoImage {
        width: 80%;
      }
      .isoText {
        width: 80%;

        h2 {
          font-size: 32px;
        }

        p {
          font-size: 20px;
        }
      }
    }
    .precisionBanner {
      width: 100%;
      // display: flex;
      // justify-content: center;
    }
    .instruments {
      width: 90%;
      display: flex;
      justify-content: center;
      .keyence {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .keyenceImage {
          width: 90%;
          img {
            padding-left: 0;
          }
        }
        .keyenceText {
          width: 90%;

          h2 {
            font-size: 28px;
          }
          p {
            font-size: 20px;
          }
        }
      }
      .cmm {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .cmmImage {
          width: 90%;
          img {
            padding-left: 0;
          }
        }
        .cmmText {
          width: 90%;
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 20px;
          }
        }
      }
      .measureLink {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .measureLinkImage {
          width: 90%;
          img {
            padding-left: 0;
          }
        }
        .measureLinkText {
          width: 90%;
          h2 {
            font-size: 28px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .precisionBanner {
      display: flex;
      justify-content: center;

      h2 {
        padding-left: 0;
      }
    }
  }
}
